import * as React from 'react'
import NavHeader from '../../../components/navHeaderEn'
import SiteFooter from '../../../components/footerEn'
import PageTitle from '../../../components/pageTitle'
import { Link } from 'gatsby'
import * as eGovernmentStyle from '../../../styles/eGovernment.module.scss'

const EGovernment = () => {
  return (
    <main>
    <NavHeader/>
    <PageTitle pageTitle="eGovernment"/>
        <div className="mainContainer">
        <div class="egovernment-container">
                <section className={eGovernmentStyle.egovSection}>
                    <div class="section-container">
                        <div className={eGovernmentStyle.articleHeading}>
                        <Link to="/en/eGovernment"></Link>
                            <h1>System for trustworthy delivery</h1>
                        </div>
                        <article className={eGovernmentStyle.articleText}>
                        <h2>Scope</h2>
                        <p>Currently, a big portion of the population uses electronic means of communication, including the communication via E-mail. Citizens and entrepreneurs therefore expect such a method to be used also in the communication with the public administration. Communication with the public administration is in many cases formalised and for this reason it is necessary for it to be secured with a regard on state processes. For example those processes includes the obligation to submit submissions within a certain time frame, guaranty of the identity of the submitting person, security of delivery, unquestionable time recording of shipment reception and so on.</p>
                        <p>On the other hand, there is a big group of those who prefer the personal contact, hesitate to submit submissions electronically and demand to have essential documents from the public administration sent in a paper form.</p>
                    </article>
                    <article className={eGovernmentStyle.articleText}>
                        <h2>Solution</h2>
                        <p>The development of electronic correspondence boxes, via which the public administration communicates with the citizens also enables the communication the other way around, citizens to the public administration. The solution includes a system of confirmation for receiving documents to the box of the institution (confirmation of submitting submissions to the institution) including time and date record of the submissions. It also includes the process of deliveries for the public and business sector.</p>
                        <p>For institutions not to struggle with the organisation connected within sending paper forms, this system is expanded by the system of Central bureau delivery (CBD).
                        </p>
                        <p>When using CBD component, every institution sends its documents merely to electronic boxes. In case the owner of the box demands the delivery is to be printed in paper form, electronic documents are directed to the central system, which transforms electronic form into paper one, also providing the printing and delivery. After the delivery information about the means and time of the delivery is sent back. All delivered documents are also saved onto the central register of official documents in case its verification in this register is needed, given there are any doubts about its authenticity. In an extended version, under strict security measures, it is possible to enable access to the register also to individual institutions, so they do not need to require from the public those documents that were issued by another institutions.</p>
                    </article>
                    <article className={eGovernmentStyle.articleText}>
                        <h2>Solution benefits</h2>
                        <h3>Citizens:</h3>
                        <ul>
                            <li>Enabling of nonstop trustworthy communication</li>
                            <li>Acceleration of proceedings</li>
                            <li>Introduction of the “once and enough” principle so that a citizen or a business object do not have to carry documents issued by one institution to another</li>
                        </ul>
                        <h3>Public administration:</h3>
                        <ul>
                            <li>Release from the obligations connected with works on the preparation of paper consignments
                            </li>
                        </ul>
                    </article>
                    </div>
                </section>
            </div>
        </div>
    <SiteFooter/>
    </main>
  )
}

export default EGovernment